<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div class="wizard-panel wizard-panel--white">
    <div class="wizard-panel__content">
      <StepHeading :step="step">
        Deploy using rsconnect-python
      </StepHeading>

      <p
        class="wizard-panel__notice pin-environment__notice"
      >
        <strong>Note</strong>
        You need to
        <a
          :href="createAPIKey"
          target="_blank"
        >
          create and use an API key
        </a>
        in order to deploy your Python application
      </p>

      <p class="wizard-panel__intro">
        Use the <code>rsconnect-python</code> package to deploy the unzipped project.
        Replace <code>&lt;api-key&gt;</code> with a valid API key.
      </p>

      <div class="wizard-panel__copy">
        <code class="wizard-panel__copy-code">{{ deployCode }}</code>
        <CopyButton
          class="wizard-panel__copy-button"
          :copy-text="deployCode"
        />
      </div>

      <div class="wizard-panel__learn-more">
        <ul class="wizard-panel__links">
          <li
            v-for="link in links"
            :key="link.title"
            class="wizard-panel__link"
          >
            <a
              :href="link.target"
              target="_blank"
            >
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CopyButton from '@/components/CopyButton';
import {
  JUPYTER_VOILA,
  PYTHON_API,
  PYTHON_BOKEH,
  PYTHON_DASH,
  PYTHON_FASTAPI,
  PYTHON_SHINY,
  PYTHON_STREAMLIT,
  PYTHON_VETIVER,
  QUARTO_PYTHON,
  QUARTO_PYTHON_SCRIPT,
  QUARTO_WEBSITE,
} from '@/constants/contentTypes';
import { docsPath, serverURL } from '@/utils/paths';
import StepHeading from '@/views/content/PublishWizard/StepHeading';
import PanelMixin from '../PanelMixin';

export default {
  name: 'PythonDeploy',
  components: {
    CopyButton,
    StepHeading,
  },
  mixins: [PanelMixin],
  data() {
    return {
      createAPIKey: docsPath('user/api-keys/#api-keys-creating'),
      links: [
        { title: 'Learn more about the rsconnect-python package in PyPI', target: 'https://pypi.org/project/rsconnect-python/' },
        { title: 'Learn more about creating and deleting API keys', target: docsPath('user/api-keys/#api-keys-creating') },
      ]
    };
  },
  computed: {
    serverAddress() {
      return serverURL('/');
    },
    deployType() {
      // Used for mapping contentTypes to supported rsconnect deploy types
      switch (this.copyFrom.type) {
        case PYTHON_API:
          return 'api';
        case PYTHON_DASH:
          return 'dash';
        case PYTHON_FASTAPI:
          return 'fastapi';
        case PYTHON_STREAMLIT:
          return 'streamlit';
        case PYTHON_BOKEH:
          return 'bokeh';
        case PYTHON_SHINY:
          return 'shiny';
        case PYTHON_VETIVER:
          return 'vetiver';
        case JUPYTER_VOILA:
          return 'voila';
        case QUARTO_PYTHON:
          return 'quarto';
        case QUARTO_PYTHON_SCRIPT:
          return 'quarto';
        case QUARTO_WEBSITE:
          return 'quarto';
      }

      throw new Error('Unhandled deploy type');
    },
    deployCode() {
      return `rsconnect deploy ${ this.deployType } --server ${ this.serverAddress } --api-key <api-key> ./`;
    },
  },
};
</script>

<style lang="scss" scoped>
.wizard-panel {
  &__copy {
    display: flex;

    &-button {
      align-self: center;
      margin-left: 0.5rem;
    }
  }
  &__links {
    margin-top: 1rem;
  }

  &__link {
    margin-bottom: .5rem;
  }
}
</style>
