<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<!-- Renders the button to run system checks. -->
<template>
  <div class="hosts-dropdown">
    <button
      type="button"
      :class="['hosts-dropdown__button', { 'hosts-dropdown__button--multihost' : buttonShouldToggleMenu }]"
      data-automation="new-system-checks-report-button"
      @click.stop="clickButton"
    >
      <span class="hosts-dropdown__button-label">Run New Report</span>
    </button>

    <div
      :class="['hosts-dropdown__menu', { 'hosts-dropdown__menu--open' : menuIsOpen }]"
    >
      <div v-if="activeHosts.length > 0">
        <button
          class="hosts-dropdown__menu-button"
          aria-label="All Currently Active Hosts"
          type="button"
          @click="runMultipleNewReports(activeHosts)"
        >
          All Currently Active Hosts
        </button>
        <div class="hosts-dropdown__menu-separator" />
        <button
          class="hosts-dropdown__menu-header"
          type="button"
        >
          Currently Active Hosts
        </button>
        <button
          v-for="(host, i) in activeHosts"
          :key="'active'+i"
          class="hosts-dropdown__menu-button"
          :aria-label="host.hostname"
          type="button"
          @click="runNewReport(host.hostname)"
        >
          {{ host.hostname }}
        </button>
      </div>

      <div v-if="inactiveHosts.length > 0">
        <div class="hosts-dropdown__menu-separator" />
        <button
          class="hosts-dropdown__menu-header"
          type="button"
        >
          Recently Active Hosts (Unavailable)
        </button>

        <button
          v-for="(host, i) in inactiveHosts"
          :key="'inactive'+i"
          class="hosts-dropdown__menu-button disabled"
          :aria-label="host.hostname"
          type="button"
        >
          {{ host.hostname }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { isAPIErrorMessage, safeAPIErrorMessage } from '@/api/error';
import { getHosts } from '@/api/hosts';
import { runSystemChecks } from '@/api/systemChecks';
import { SHOW_ERROR_MESSAGE } from '@/store/modules/messages';
import { mapActions } from 'vuex';
import { timeElapsedSince } from './utils';

export default {
  name: 'RunNewReportButton',
  emits: ['newReport'],
  data() {
    return {
      allHosts: [],
      activeHosts: [],
      inactiveHosts: [],
      menuIsOpen: false,
    };
  },
  computed: {
    multihost() {
      return this.allHosts.length > 1;
    },
    buttonShouldToggleMenu() {
      return this.multihost === true || this.menuIsOpen === true;
    },
  },
  created() {
    this.updateHostsList();
    window.addEventListener('click', this.clickOff);
  },
  unmounted() {
    window.removeEventListener('click', this.clickOff);
  },
  methods: {
    ...mapActions({
      setErrorMessage: SHOW_ERROR_MESSAGE,
    }),
    clickButton() {
      this.updateHostsList();
      if (this.buttonShouldToggleMenu === true) {
        this.menuIsOpen = !this.menuIsOpen;
      } else {
        this.runNewReport(null);
      }
    },
    updateHostsList() {
      return getHosts()
        .then(res => {
          this.allHosts = res
            .filter(host => {
              return timeElapsedSince(host.lastSeen).inHours < 1;
            })
            .sort((a, b) => a.hostname.localeCompare(b.hostname));
          // We manually compute activeHosts, rather than use the API,
          // so that we can partition the list into active/inactive.
          this.activeHosts = this.allHosts.filter(host => {
            return timeElapsedSince(host.lastSeen).inMinutes < 1;
          });
          this.inactiveHosts = this.allHosts.filter(host => {
            return timeElapsedSince(host.lastSeen).inMinutes >= 1;
          });
        });
    },
    runNewReport(hostname) {
      runSystemChecks(hostname)
        .then(() => this.$emit('newReport'))
        .catch(err => {
          if (isAPIErrorMessage(err)) {
            this.setErrorMessage({
              message: `${safeAPIErrorMessage(err)} (${hostname})`,
            });
          } else {
            console.log(err);
          }
        });
    },
    runMultipleNewReports(hosts) {
      const promises = hosts
        .map(host => {
          return runSystemChecks(host.hostname)
            .then(() => Promise.resolve({ hostname: host.hostname, success: true }))
             
            .catch(() => Promise.resolve({ hostname: host.hostname, success: false }));
        });
      Promise.all(promises)
        .then(results => {
          return results.reduce((obj, { hostname, success }) => {
            if (success === true) {
              obj.successes.push(hostname);
            } else {
              obj.failures.push(hostname);
            }
            return obj;
          }, { successes: [], failures: [] });
        })
        .then(reduced => {
          if (reduced.successes.length > 0) {
            this.$emit('newReport');
          }
          if (reduced.failures.length > 0) {
            const failedHosts = reduced.failures.join(', ');
            this.setErrorMessage({
              message: `Unable to create a report for some hosts (${failedHosts})`,
            });
          }
        });
    },
    clickOff() {
      this.menuIsOpen = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.hosts-dropdown {
  display: inline-block;
  position: relative;

  &__button {
    height: 30px;
    min-width: 0px;
    vertical-align: middle;
    color: $color-white;
    padding: 0 25px;
    border-radius: 4px;
    background-color: $color-primary;

    &.hosts-dropdown__button--multihost {
      padding: 0 30px 0 20px;
      margin-left: 10px;
      text-align: left;
      background-position: right center;
      background-size: 30px 30px;
      background-image: url('/images/elements/actionMenuDropdown.svg');
      background-repeat: no-repeat;
    }
  }

  &__menu {
    @include normal-drop-shadow;
    display: none;
    position: absolute;
    right: 0px;
    z-index: 1000;
    line-height: 40px;
    background-color: $color-white;
    text-align: left;
    font-size: 13px;
    border-radius: 4px;
    white-space: nowrap;

    &.hosts-dropdown__menu--open {
      display: block;
    }
  }

  &__menu-header {
    display: block;
    height: 30px;
    width: 100%;
    padding: 0px 15px;
    text-align: left;
    color: $color-dark-grey;
    background-color: transparent;
    pointer-events: none;
    font-size: 12px;
    font-weight: 700;
  }

  &__menu-button {
    display: block;
    height: 30px;
    width: 100%;
    padding: 0px 15px;
    text-align: left;
    background-color: transparent;

    &:hover {
      background-color: $color-light-grey-2;
    }

    &.disabled {
      pointer-events: none;
      color: $color-dark-grey;
      opacity: 1; // To override the default button style
    }

  }

  .inactive {
    background-color: transparent;
    color: $color-dark-grey;
  }

  &__menu-separator {
    line-height: 1px;
    height: 1px;
    margin: 4px 0 5px 0;
    border-bottom: 1px dotted $color-light-grey-4;
    cursor: default;
  }
}
</style>
