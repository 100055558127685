<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <RSTable
    :columns="tableHeaders"
    data-automation="user-api-keys-table"
    @sort="onSortChange"
  >
    <RSTableRow
      v-for="(apiKey, index) in keys"
      :key="apiKey.id"
      :row-id="apiKey.id"
      :deletable="true"
      :delete-button-label=" `Delete the ${apiKey.name} API key`"
      :row-label=" `API Key ${apiKey.name}`"
      :data-automation="`api-key-item-${index}`"
      @delete="() => $emit('deleteIntention', apiKey)"
    >
      <RSTableCell
        :cell-id="`api-key-${index}-name`"
        :data-automation="`api-key-${index}-name`"
        :has-icon="true"
      >
        <span class="key-icon typeAPIKey" />
        {{ apiKey.name }}
      </RSTableCell>
      <RSTableCell
        :cell-id="`api-key-${index}-key`"
        :data-automation="`api-key-${index}-key`"
      >
        {{ apiKey.key }}
      </RSTableCell>
      <RSTableCell
        :cell-id="`api-key-${index}-created`"
        :data-automation="`api-key-${index}-created`"
      >
        <time>{{ apiKey.created }}</time>
      </RSTableCell>
      <RSTableCell
        :cell-id="`api-key-${index}-lastused`"
        :data-automation="`api-key-${index}-lastused`"
      >
        <time>{{ apiKey.lastUsed }}</time>
      </RSTableCell>
      <RSTableCell
        class="role"
        :cell-id="`api-key-${index}-userrole`"
        :data-automation="`api-key-${index}-userrole`"
      >
        <div
          :class="apiKey.userRole > currentUser.userRole ? 'inaccurate' : null"
        >
          {{ roleText(apiKey.userRole) }}
        </div>
        <div
          v-if="apiKey.userRole > currentUser.userRole"
          class="privs"
        >
          Has {{ roleText(currentUser.userRole) }} privileges
        </div>
      </RSTableCell>
    </RSTableRow>
  </RSTable>
</template>

<script>
import UserRoles from '@/api/dto/userRole';
import RSTable from '@/elements/RSTable';
import RSTableCell from '@/elements/RSTableCell';
import RSTableRow from '@/elements/RSTableRow';
import dayjs from 'dayjs';
import upperFirst from 'lodash/upperFirst';

export default {
  name: 'KeysTable',
  components: {
    RSTable,
    RSTableRow,
    RSTableCell,
  },
  props: {
    keys: {
      type: Array,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true
    }
  },
  emits: ['deleteIntention'],
  data() {
    return {
      tableHeaders: [
        { name: 'name', label: 'Name', sortable: true },
        { name: 'key', label: 'Key' },
        { name: 'created', label: 'Created', sortable: true, direction: 'asc' },
        { name: 'lastUsed', label: 'Last Used', sortable: true },
        { name: 'role', label: 'Role', sortable: true, size: 2 },
      ],
    };
  },
  methods: {
    onSortChange({ index, direction }) {
      const activeColumn = this.tableHeaders[index];
      this.tableHeaders.forEach(c => {
        c.direction = null;
      });
      const descending = direction === 'desc';
      activeColumn.direction = descending ? 'desc' : 'asc';

      const compare = (first, second) => {
        const invert = descending ? -1 : 1;
        switch(activeColumn.name) {
          case 'created':
            return invert * (dayjs(first.created) - dayjs(second.created));
          case 'lastUsed': 
            return invert * (dayjs(first.lastUsed) - dayjs(second.lastUsed));
          case 'name':
            return invert * (first.name.localeCompare(second.name));
          case 'role':
            return invert * (first.userRole - second.userRole);
        }
      };
      const sortedKeys = this.keys;
      return sortedKeys.sort(compare); 
    },
    roleText(role) {
      const text = UserRoles.stringOf(role);
      return upperFirst(text);
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
.key-icon {
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  height: 2rem;
  padding-right: 1rem;
  vertical-align: middle;
  width: 2rem;
}
.role {
  line-height: 1.1rem;
}
.inaccurate {
  text-decoration: line-through;
}
.privs {
  color: $color-dark-grey;
  font-style: italic;
}
</style>
