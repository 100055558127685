// Copyright (C) 2022 by Posit Software, PBC.

import axios from 'axios';

import { ApiKey } from '@/api/dto/apiKey';
import { CurrentUser, User } from '@/api/dto/user';
import { keysToCamel, keysToSnake } from '@/api/transform';
import { apiPath, apiV1Path, userPreferencesPath } from '@/utils/paths';

export function getCurrentUser() {
  return axios
    .get(apiPath('me'))
    .then(({ data }) => new CurrentUser(keysToCamel(data)));
}

export function getUser(userGuid) {
  return axios
    .get(apiV1Path(`users/${encodeURIComponent(userGuid)}`))
    .then(({ data }) => new User(keysToCamel(data)));
}

export function getPermissions(guid) {
  return axios
    .get(apiPath(`users/${encodeURIComponent(guid)}/permissions`))
    .then(({ data }) => keysToCamel(data));
}

export function updateUser(guid, data) {
  return axios
    .put(apiV1Path(`users/${encodeURIComponent(guid)}`), keysToSnake(data))
    .then(({ data: responseData }) => keysToCamel(responseData));
}

export function updateUserPreferences(guid, preferences) {
  return axios.put(userPreferencesPath(guid), keysToSnake(preferences));
}

/**
 * Get a list of the API keys for the logged in user.
 *
 * @returns {Promise<Array<ApiKey>>}
 */
export function getCurrentUserAPIKeys() {
  return axios
    .get(apiPath('keys'))
    .then(({ data }) => keysToCamel(data).map(k => new ApiKey(k)));
}

/**
 * Create a new API key for the logged in user.
 *
 * @param {string} [keyName] - The name for the new API key.
 * @param {string} [userRole] - The user permission level for the new API key.
 * @returns {Promise<ApiKey>}
 */
export function createUserAPIKey(keyName, userRole) {
  return axios
  // eslint-disable-next-line camelcase
    .post(apiPath('keys'), { name: keyName, user_role: userRole })
    .then(({ data }) => new ApiKey(keysToCamel(data)));
}

/**
 * Delete an API key by id for the logged in user.
 *
 * @param {string|number} [keyId] - The id of the API key to be deleted.
 * @returns {Promise}
 */
export function deleteUserAPIKey(keyId) {
  return axios.delete(apiPath(`keys/${encodeURIComponent(keyId)}`));
}

/**
 * Activate IDE token to connect user account.
 *
 * @param {string} [token] - The token
 * @returns {Promise}
 */
export function connectUserToken(token) {
  return axios.post(apiPath(`tokens/${token}/activate`), { token });
}

/**
 * Searches the system for users (local and/or remote).
 *
 * @param {Object} serverSettings - server settings object
 * @param {string} [prefix] - restricts search results to users containing the specified prefix
 * @param {Object} [accountStatus] - restricts search results based on account status. If none are specified, all are included.
 * @param {boolean} [accountStatus.inactive] - include inactive users
 * @param {boolean} [accountStatus.licensed] - include licensed users
 * @param {boolean} [accountStatus.locked] - include locked users
 * @param {Object} [userRole] - restricts search results based on account role. If none are specified, all are included.
 * @param {boolean} [userRole.administrator] - include administrators
 * @param {boolean} [userRole.publisher] - include publishers
 * @param {boolean} [userRole.viewer] - include viewers
 * @param {boolean} [includeRemote] - include remote users
 * @param {number} [pageSize=10] - number of search results to return
 * @param {number} [pageNumber=1] - the page of search results to return
 * @returns {Promise<{totalPages: number, currentPage: number, results: Array<User>}>}
 */
export function searchUsers(
  serverSettings,
  {
    prefix,
    accountStatus = {},
    userRole = {},
    includeRemote = true,
    pageSize = 10,
    pageNumber = 1,
  } = {}
) {
  const path =
    serverSettings.authentication.externalUserSearch && includeRemote
      ? apiV1Path('users/remote')
      : apiV1Path('users');

  const queryParams = { pageSize, pageNumber };

  if (prefix) {
    queryParams.prefix = prefix;
  }

  const statuses = Object.entries(accountStatus)
    .filter(([, set]) => set)
    .map(([statusName]) => statusName)
    .join('|');

  const roles = Object.entries(userRole)
    .filter(([, set]) => set)
    .map(([roleName]) => roleName)
    .join('|');

  if (statuses) {
    queryParams.accountStatus = statuses;
  }

  if (roles) {
    queryParams.userRole = roles;
  }

  return axios
    .get(path, { params: keysToSnake(queryParams) })
    .then(({ data }) => {
      const { results, currentPage, total } = keysToCamel(data);
      return {
        results: results.map(u => new User(u)),
        currentPage,
        totalPages: Math.ceil(total / pageSize),
      };
    });
}

/**
 * Adds a remote user to the system.
 *
 * @param {string} tempTicket - temporary code received when searching for users
 * @returns {Promise<Object>} - empty object
 */
export function addNewRemoteUser(tempTicket) {
  return axios
    .put(apiV1Path('users'), keysToSnake({ tempTicket }))
    .then(({ data }) => new User(keysToCamel(data)));
}

/**
 * Add a new local user to the system (just for password auth provider).
 *
 * @param {string} username - username
 * @param {string} email - user's email address
 * @param {string} [firstName] - first name
 * @param {string} [lastName] - last name
 * @param {string} [password] - password
 * @param {string} userRole - user role
 * @param {string} [userMustSetPassword] - user role
 * @returns {Promise<User>} - created user
 */
export function addNewLocalUser({
  username,
  email,
  firstName = '',
  lastName = '',
  password = '',
  userRole,
  userMustSetPassword = true,
} = {}) {
  return axios
    .post(
      apiV1Path('users'),
      keysToSnake({
        username,
        email,
        firstName,
        lastName,
        userRole,
        // create with empty password so user has to set a password when they confirm account creation
        password,
        userMustSetPassword,
      })
    )
    .then(({ data }) => new User(keysToCamel(data)));
}

/**
 * Locks a user's account.
 *
 * @param {string} userGuid - GUID of the user
 * @returns {Promise<()>}
 */
export function lockUser(userGuid) {
  return axios.post(apiV1Path(`users/${encodeURIComponent(userGuid)}/lock`), {
    locked: true,
  });
}

/**
 * Unlocks a user's account.
 *
 * @param {string} userGuid - GUID of the user
 * @returns {Promise<()>}
 */
export function unlockUser(userGuid) {
  return axios.post(apiV1Path(`users/${encodeURIComponent(userGuid)}/lock`), {
    locked: false,
  });
}
