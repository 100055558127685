<!-- Copyright (C) 2022 by Posit Software, PBC. -->

<template>
  <div
    id="navPanel"
    class="sidePanel"
  >
    <div class="panelContents">
      <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
      <nav
        v-if="shouldShowNav"
        aria-label="Site Navigation"
        class="navMenu"
        tabindex="-1"
        @click="closePanel"
        @keydown.esc="closePanel"
      >
        <FocusLock group="sidePanel">
          <NavPanelMenu
            title="Content"
            data-automation="left-nav-content"
            name="contentList"
          />

          <NavPanelMenu
            title="People"
            data-automation="left-nav-people"
            name="people"
            :children="getPeopleMenuItems()"
          />

          <NavPanelMenu
            v-if="shouldShowEnvironmentsMenu"
            title="Environments"
            data-automation="left-nav-image"
            name="environments"
          />

          <NavPanelMenu
            v-if="shouldShowIntegrations"
            title="Integrations"
            data-automation="left-nav-integrations"
            name="integrations"
          />

          <div v-if="isAdmin">
            <NavPanelMenu
              title="Admin"
              data-automation="left-nav-admin"
              name="admin"
              :children="getAdminMenuItems()"
            />
          </div>
          <div
            v-if="!viewerHideDocs"
            :class="{ 'show-notification': showNotification }"
          >
            <NavPanelMenu
              title="Documentation"
              data-automation="left-nav-doc"
              name="help"
              :children="getDocumentationMenuItems()"
            />
          </div>
        </FocusLock>
      </nav>
    </div>

    <footer class="panelFooter">
      <div class="finePrint">
        <div
          v-if="serverSettings.about"
          data-automation="about"
        >
          {{ serverSettings.about }}
        </div>
        <div
          v-if="serverSettings.build"
          data-automation="build"
        >
          Build {{ serverSettings.build }}
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import FocusLock from 'vue-focus-lock';
import NavPanelMenu from './NavPanelMenu.vue';

import { ExecutionTypeK8S } from '@/api/serverSettings';
import { SHOW_NAV_PANEL } from '@/store/modules/navigation';
import { docsPath } from '@/utils/paths';
import { mapMutations, mapState } from 'vuex';

const standardDocMenuItems = [
  {
    title: 'User Guide',
    href: docsPath('user'),
    class: 'userGuide',
    dataAutomation: 'left-nav-userguide',
    target: '_blank',
  }, {
    title: 'Admin Guide',
    href: docsPath('admin'),
    class: 'adminGuide',
    dataAutomation: 'left-nav-adminguide',
    target: '_blank',
  }, {
    title: 'FAQ',
    href: 'https://support.posit.co/hc/en-us/sections/205987508-RStudio-Connect',
    class: 'faq',
    dataAutomation: 'left-nav-faq',
    target: '_blank',
  }, {
    title: 'Articles',
    href: 'https://support.posit.co/hc/en-us/sections/204688508-RStudio-Connect',
    class: 'articles',
    dataAutomation: 'left-nav-articles',
    target: '_blank',
  }, {
    title: 'Release Notes',
    href: docsPath('news'),
    class: 'releaseNotes',
    dataAutomation: 'left-nav-releasenotes',
    target: '_blank',
  }, {
    title: 'Server API Reference',
    href: docsPath('api'),
    class: 'apiDocs',
    dataAutomation: 'left-nav-apidocs',
    target: '_blank',
  }, {
    title: 'Cookbook',
    href: docsPath('cookbook'),
    class: 'apiDocs',
    dataAutomation: 'left-nav-apidocs-cookbook',
    target: '_blank',
  }
];

const whatsNewMenuItem = {
  title: `What's New`,
  name: 'whatsnew',
  class: 'whatsNew',
  dataAutomation: 'left-nav-whats-new',
};

export default {
  name: 'NavPanel',
  components: {
    FocusLock,
    NavPanelMenu,
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.currentUser.isAuthenticated,
      currentUser: state => state.currentUser.user,
      serverSettings: state => state.server.settings,
      showNotification: state => state.whatsNew.showNotification,
    }),
    isAdmin() {
      return this.currentUser.isAdmin();
    },
    isPublisher() {
      return this.currentUser.isPublisher();
    },
    viewerHideDocs() {
      return !this.currentUser.canPublish() && this.serverSettings.hideViewerDocumentation;
    },
    shouldShowNav() {
      return Boolean(this.isAuthenticated && Object.keys(this.serverSettings).length);
    },
    shouldShowIntegrations() {
      return (this.serverSettings.oauthIntegrationsEnabled && (this.isPublisher || this.isAdmin));
    },
    shouldShowEnvironmentsMenu() {
      return Boolean(
        this.serverSettings.executionType === ExecutionTypeK8S &&
          (this.isPublisher || this.isAdmin)
      );
    },
  },
  methods: {
    ...mapMutations({
      showingNavPanel: SHOW_NAV_PANEL,
    }),
    closePanel() {
      this.showingNavPanel(false);
      this.$nextTick().then(() => {
        document.querySelector('#app')?.focus();
      });
    },

    getPeopleMenuItems() {
      if ((!this.serverSettings.authentication.externalGroupId
        || this.serverSettings.authentication.externalGroupSearch)
        && this.serverSettings.authentication.groupsEnabled) {
        return [
          {
            title: 'Users',
            name: 'people.users',
            class: 'users',
            dataAutomation: 'left-nav-users'
          },
          {
            title: 'Groups',
            name: 'people.groups',
            class: 'groups',
            dataAutomation: 'left-nav-groups'
          },
        ];
      }

      return [];
    },
    getAdminMenuItems() {
      const items = [
        {
          title: 'Metrics',
          name: 'admin.metrics',
          class: 'metrics',
          dataAutomation: 'left-nav-metrics',
        }, {
          title: 'Mail Settings',
          name: 'admin.mail_settings',
          class: 'settings',
          dataAutomation: 'left-nav-settings',
        }, {
          title: 'Tags',
          name: 'admin.tag_editor',
          class: 'tags',
          dataAutomation: 'left-nav-tags',
        }, {
          title: 'Audit Logs',
          name: 'admin.audit_logs',
          class: 'auditLogs',
          dataAutomation: 'left-nav-logs',
        }, {
          title: 'Scheduled Content',
          name: 'admin.scheduled_content',
          class: 'scheduled',
          dataAutomation: 'left-nav-scheduled',
        }, {
          title: 'Processes',
          name: 'admin.processes',
          class: 'processes',
          dataAutomation: 'left-nav-processes',
        }, {
          title: 'System Checks',
          name: 'admin.system_checks',
          class: 'systemChecks',
          dataAutomation: 'left-nav-systemchecks',
        }, {
          title: 'Queue',
          name: 'admin.queue',
          class: 'queue',
          dataAutomation: 'left-nav-queue',
        },
      ];

      return items;
    },
    getDocumentationMenuItems() {
      const menuItems = [...standardDocMenuItems];

      if (this.serverSettings.whatsNewEnabled) {
        menuItems.unshift(whatsNewMenuItem);
      };

      return menuItems;
    },
  }
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_variables';

#navPanel {
  left: 0px;
  width: $nav-panel-width;
  background-color: $color-light-grey;

  .show-notification {
    position: relative;
  }

  .navMenu {
    color: $color-dark-grey-3;
    font-size: 13px;
    overflow-x: hidden;
  }

  .panelContents {
    min-height: 100%;
    margin-bottom: -$nav-panel-footer-height;
  }

  .panelContents:after {
    content: "";
    display: block;
  }

  .panelFooter, .panelContents:after {
    height: $nav-panel-footer-height;
  }

  .finePrint {
    color: $color-dark-grey-3;
    line-height: 20px;
    font-size: 11px;
    padding: 0 $side-panel-padding;
  }
}
</style>
