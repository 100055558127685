// Copyright (C) 2024 by Posit Software, PBC.

import isEmpty from 'lodash/isEmpty';
import { updateUserPreferences } from '@/api/users';
import { getConnectFeed, filterConnectFeed } from '@/api/connectFeed.js';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';

dayjs.extend(isSameOrAfter);
dayjs.extend(utc);

export const HAS_FEED_ERROR = 'HAS_FEED_ERROR';
export const IS_FEED_LOADING = 'IS_FEED_LOADING';
export const LOAD_CONNECT_FEED = 'LOAD_CONNECT_FEED';
export const SET_CONNECT_FEED = 'SET_CONNECT_FEED';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const UPDATE_LAST_VIEWED_TIMESTAMP = 'UPDATE_LAST_VIEWED_TIMESTAMP';

export default {
  state: {
    feed: [],
    hasFeedError: false,
    showNotification: false,
    isLoading: true,
  },
  mutations: {
    [HAS_FEED_ERROR](state, payload) {
      state.hasFeedError = payload;
    },
    [IS_FEED_LOADING](state, payload) {
      state.isLoading = payload;
    },
    [SET_CONNECT_FEED](state, payload) {
      state.feed = payload;
    },
    [SHOW_NOTIFICATION](state, payload) {
      state.showNotification = payload;
    },
  },
  actions: {
    [LOAD_CONNECT_FEED]({ commit, rootState: { currentUser: { user } } }) {
      return new Promise((resolve, reject) => {
        getConnectFeed()
          .then(data => {
            if (isEmpty(data)) {
              commit(HAS_FEED_ERROR, true);
              return;
            }
            const filteredItems = filterConnectFeed(data, user.userRole);
            commit(SET_CONNECT_FEED, filteredItems);
            commit(IS_FEED_LOADING, false);
            const firstItemPubDate = filteredItems[0]?.pubDate;
            const lastViewed = user.preferences?.whatsNewLastViewed;

            // The whats_new_last_viewed timestamp is initially set to null
            if (!user.preferences || lastViewed === null) {
              commit(SHOW_NOTIFICATION, true);
            }

            // Show notification if first feed item is newer than the last viewed timestamp
            if (dayjs(firstItemPubDate).utc().isSameOrAfter(lastViewed)) {
              commit(SHOW_NOTIFICATION, true);
            }
            resolve(data);
          })
          .catch(err => {
            commit(IS_FEED_LOADING, false);
            commit(HAS_FEED_ERROR, true);
            reject(err);
          });
      });
    },
    [UPDATE_LAST_VIEWED_TIMESTAMP]({ rootState: { currentUser: { user } } }, value) {
      return updateUserPreferences(user.guid, { whatsNewLastViewed: value })
        .catch(e => {
          throw new Error(`${e.response}: Unable to set last viewed timestamp`);
        });
    },
  },
};
