<!-- Copyright (C) 2024 by Posit Software, PBC. -->

<script setup>
import { computed, onBeforeMount, reactive } from 'vue';
import { useStore } from 'vuex';
import { createIntegration, getIntegrationInfo, getIntegrationTemplate, updateIntegration } from '@/api/oauth';
import RSButton from '@/elements/RSButton';
import RSModalForm from '@/elements/RSModalForm';
import RSInputSelect from '@/elements/RSInputSelect';
import RSInputPassword from '@/elements/RSInputPassword';
import RSInputText from '@/elements/RSInputText';
import { SET_ERROR_MESSAGE_FROM_API } from '@/store/modules/messages';
import { camelCase, isEmpty } from 'lodash';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

const props = defineProps({
  guid: {
    type: String,
    default: '',
  },
  readOnly: {
    type: Boolean,
    default: true,
  },
  templates: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['close', 'createSuccess', 'error', 'updateSuccess']);

const store = useStore();

const localState = reactive({
  ready: false,
  template: {},
  integration: {},
  form: {
    name: '',
    description: '',
    template: '',
    config: {}
  }
});

const listTemplates = computed(() => 
  props.templates.map(item => {return { label: item.name, value: item.id };}));

const configFields = computed(() => {
  let allFields = localState.template?.fields;
  if (localState.template.options) {
    allFields = allFields.concat(localState.template.options);
  }
  allFields.sort((a,b) => a.order - b.order);
  return allFields;
});

const templateImage = computed(() => {
  if (localState.template?.id) {
    return { '--img-src': `url(/connect/images/oauthintegrations/${localState.template.id}.png)` };
  }
  return { '--img-src': 'url(/connect/images/oauthintegrations/integration_icon.svg)' };
});

const isFieldVisible = (input) => {
  // if we add fields with multiple visibility conditions, we'll need to rethink this.
  if(input.visibility &&
    localState.form.config[camelCase(input.visibility[0].name)] !== input.visibility[0].value
  ) {
    return false;
  };
  return true;
};

let v$;
const validations = computed(() => {
  const validationsObj = {
    name: { required },
    description: { },
    template: { required },
    config: { }
  };
  for (const field in configFields.value) {
    const fieldObject = configFields.value[field];
    if (fieldObject.name === 'scopes' || // scopes are allowed to be empty
      !isFieldVisible(fieldObject) || // don't validate fields that aren't currently in the UI
      (props.guid && fieldObject.secret) // secret fields are not returned when updating, so they can be empty on submit
    )
    {
      validationsObj.config[camelCase(fieldObject.name)] = { };
    } else if (fieldObject.validation) {
      const regex = helpers.regex(new RegExp(fieldObject.validation));
      validationsObj.config[camelCase(fieldObject.name)] = { required, regex };   
    } else {
      validationsObj.config[camelCase(fieldObject.name)] = { required };
    }
  }
  return validationsObj;
});

const loadTemplate = async(val) => {
  try {
    localState.template = await getIntegrationTemplate(val);
  } catch (e) {
    store.commit(SET_ERROR_MESSAGE_FROM_API, e);
  }
  
  localState.form.template = val;

  for (const field in configFields.value) {
    const fieldObject = configFields.value[field];
    // get fields needed for this template
    if (fieldObject.default) {
      localState.form.config[camelCase(fieldObject.name)] = fieldObject.default;
    } else {
      localState.form.config[camelCase(fieldObject.name)] = '';
    }
  }
  v$ = useVuelidate(validations, localState.form);
};

const errorMessage = (field) => {
  const validation = v$.value.config[camelCase(field.name)];
  if (validation.$error && validation.$errors[0].$validator === 'regex') {
    return `${field.label} must match the regular expression ${field.validation}`;
  } else if (validation.$error) {
    return `${field.label} is required`;
  }
  return null;
};

const onSubmit = async(guid) => {
  const ok = await v$.value.$validate();
  if (!ok) {return;}

  const final = { 
    name: localState.form.name,
    description: localState.form.description,
    config: {}
  };

  for (const field of configFields.value) {
    const formField = localState.form.config[camelCase(field.name)];
    if (isFieldVisible(field) && !field.secret) {
      // if its visible, send it along whether or not its changed
      final.config[field.name] = formField;
    } else if (isFieldVisible(field) && field.secret && !!formField) {
      // only send along secrets if there's a value, since we don't populate
      final.config[field.name] = formField;
    } else if (!isFieldVisible(field)) {
      // any field that isn't shown should be empty/null
      final.config[field.name] = null;
    }
  }

  try {
    if (guid) {
      await updateIntegration(guid, final);
      emit('updateSuccess', final.name);
      emit('close');
    } else {
      final.template = localState.form.template;
      await createIntegration(final);
      emit('createSuccess', final.name);
      emit('close');
    }
  }
  catch(e) {
    emit('error', e);
  }
};

onBeforeMount(async() => {
  try {
    if (props.guid) {
      localState.integration = await getIntegrationInfo(props.guid);
      // fill in Connect-only fields
      localState.form.name = localState.integration.name;
      localState.form.description = localState.integration.description;
      localState.form.template = localState.integration.template;
      for (const field in localState.integration.config) {
        // fill in fields that get sent to provider
        localState.form.config[field] = localState.integration.config[field];
      }
      localState.template = await getIntegrationTemplate(localState.integration.template);
      v$ = useVuelidate(validations, localState.form);
    }
    localState.ready = true;
  } catch (e) {
    store.commit(SET_ERROR_MESSAGE_FROM_API, e);
  }
});
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- any HTML is coming directly from the server, and going into info/help text only. -->
  <RSModalForm
    v-if="localState.ready"
    :active="true"
    :subject="!guid ? 'New OAuth Integration' : localState.template.name"
    :style="templateImage"
    @close="() => $emit('close')"
  >
    <template #content>
      <div class="fields">
        <RSInputText
          v-model="localState.form.name"
          :disabled="props.readOnly"
          required
          autocomplete="off"
          data-automation="integration-modal__title"
          label="Title"
          name="integration_title"
          help="The name for this integration within Connect.
          The name should be short and meaningful to publishers."
          :message="
            (!isEmpty(localState.template) && v$.name.$error) ?
              'Title is required' :
              null
          "
          @change="!isEmpty(localState.template) ? v$.name.$touch() : null"
        />
        <RSInputText
          v-model="localState.form.description"
          :disabled="props.readOnly"
          autocomplete="off"
          data-automation="integration-modal__description"
          label="Description"
          name="integration_description"
          :lines="5"
          @change="!isEmpty(localState.template) ? v$.description.$touch() : null"
        />
      </div>
      <div
        v-if="!props.guid"
        class="template-select"
      >
        <RSInputSelect
          data-automation="integration-modal__template"
          label="Select Integration"
          name="template"
          :options="listTemplates"
          :model-value="localState.template.id"
          @change="val => loadTemplate(val)"
        />
      </div>
      <div v-if="localState.ready && (props.guid || !isEmpty(localState.template))">
        <h2
          v-if="!props.guid && !isEmpty(localState.template)"
          class="template-name"
        >
          <img
            :src="`images/oauthintegrations/${localState.template.id}.png`"
            alt="''"
            class="template-image"
          >
          {{ localState.template.name }}
        </h2>
        <h3
          class="template-description"
          v-html="localState.template.description"
        />
        <div
          v-for="input of configFields"
          :key="input.label"
          class="fields"
        >
          <RSInputSelect
            v-if="input.type === 'select' && isFieldVisible(input)"
            v-model="localState.form.config[camelCase(input.name)]"
            :disabled="props.readOnly || input.values.length < 2"
            :data-automation="`integration-modal__${input.name}`"
            :label="input.label"
            :name="input.name"
            :help="input.description"
            :options="(input.values).map(each => {return { label: each, value: each }})"
            @change="v$.config[camelCase(input.name)].$touch()"
          >
            <template
              v-if="input.description"
              #help
            >
              <span v-html="input.description" />
            </template>
          </RSInputSelect>
          <RSInputPassword
            v-else-if="input.secret && isFieldVisible(input)"
            v-model="localState.form.config[camelCase(input.name)]"
            autocomplete="off"
            :disabled="props.readOnly"
            :data-automation="`integration-modal__${input.name}`"
            :label="input.label"
            :name="input.name"
            :help="input.description"
            :toggle-visibility="
              !props.readOnly && localState.form.config[camelCase(input.name)]?.length > 0"
            :required="!isEmpty(validations.config[camelCase(input.name)])"
            placeholder="****"
            :message="errorMessage(input)"
            @change="v$.config[camelCase(input.name)].$touch()"
          >
            <template
              v-if="input.description"
              #help
            >
              <span v-html="input.description" />
            </template>
          </RSInputPassword>
          <RSInputText
            v-else-if="isFieldVisible(input)"
            v-model="localState.form.config[camelCase(input.name)]"
            autocomplete="off"
            :spellcheck="false"
            :disabled="props.readOnly"
            :data-automation="`integration-modal__${input.name}`"
            :label="input.label"
            :lines="input.multiline ? 5 : 1"
            :name="input.name"
            :required="!isEmpty(validations.config[camelCase(input.name)])"
            :message="errorMessage(input)"
            @change="v$.config[camelCase(input.name)].$touch()"
          >
            <template
              v-if="input.description"
              #help
            >
              <span v-html="input.description" />
            </template>
          </RSInputText>
        </div>
      </div>
    </template>
    <template
      v-if="!props.readOnly"
      #controls
    >
      <RSButton
        data-automation="integration-modal__cancel-button"
        type="secondary"
        label="Cancel"
        class="button cancel"
        @click="() => $emit('close')"
      />
      <RSButton
        v-if="!isEmpty(localState.template)"
        type="primary"
        class="button"
        :disabled="!v$.$anyDirty || v$.$errors.length !== 0"
        :label="!props.guid ? 'Add' : 'Update'"
        :data-automation="!props.guid ? 'integration-modal__add-button' : 'integration-modal__update-button'"
        @click="onSubmit(props.guid || null)"
      />
    </template>
  </RSModalForm>
  <!-- eslint-enable vue/no-v-html -->
</template>

<style lang="scss" scoped>
@import 'Styles/shared/_colors';
  .rs-modal {
    :deep(.rs-modal__title) {
      &::before {
        content: '';
        background-image: var(--img-src);
        height: 24px;
        width: 24px;
        background-size: 24px 24px;
        display: inline-flex;
        margin-right: 0.5rem;
        top: 4px;
        position: relative;
      }  
    }
    :deep(.rs-modal__actions) {
      justify-content: flex-start;
      button {
        min-width: 100px;
        &.cancel {
          margin-right: 1rem;
        }
      }
    }
    :deep(.rs-modal__header) {
      padding-bottom: 1rem;
      border-bottom: 1px solid $color-light-grey-4;
    }
    :deep(.rs-modal__dialog) {
      max-width: 900px;
    }
  }
  :deep(.rs-field) {
    .rs-field__help {
      justify-content: start;   
    }
    .rs-field__error {
      &::before {
        content: '';
        background-image: url(/images/elements/iconError.svg);
        background-repeat: no-repeat;
        height: 16px;
        position: relative;
        width: 16px;
        display: inline-block;
        background-size: contain;
        top: 3px;
        margin-right: 4px;
      }
    }
    textarea.rs-input {
      max-height: 15rem;
    }
  }
  .template-select {
    width: 80%;
    padding-bottom: 2rem;
  }
  .template-name {
    font-size: 1rem;
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }
  .template-image {
    width: 20px;
    height: 20px;
    margin-right: 0.25rem;
  }
  .template-description {
    font-size: 0.9rem;
    padding-bottom: 1rem;
  }
  .fields {
    .rs-field {
      width: 80%;
      padding-bottom: 0.5rem;
      margin-bottom: 0.9rem;
    }
  }
</style>
