<!-- Copyright (C) 2023 by Posit Software, PBC. -->

<template>
  <div
    :id="`${typeProse}-history-container`"
    class="historyBarContainer"
    :data-automation="`${typeProse}-history`"
  >
    <div class="actionBar inline">
      <!-- Uncomment the line below when implementing the floating window UI for history: -->
      <!-- <button class="action showHistoryPanel first" disabled="disabled"></button> -->

      <button
        class="action arrowLeft"
        :disabled="!shouldEnableLeftNavigation"
        :aria-label="`Older ${capitalize(typeProse)}s`"
        @click="doScrollLeft"
      />
      <button
        class="action arrowRight first"
        :disabled="!shouldEnableRightNavigation"
        :aria-label="`Newer ${capitalize(typeProse)}s`"
        @click="doScrollRight"
      />
    </div>
    <div
      ref="innerContainer"
      class="listOuterContainer"
    >
      <div class="listContainer">
        <div class="listbox">
          <button
            v-for="(item, index) in historyList"
            ref="item"
            :key="item.id"
            :data-automation="`history-item-${index + 1}`"
            class="item"
            :class="{
              selected: Number(item.id) === displayedId,
              active: item.active
            }"
            @click="onDisplayItem(item, index)"
          >
            {{ item[dateField] }}
          </button>
        </div>
      </div>
    </div>
    <div class="info-toggle-container">
      <i
        class="infoToggle"
        data-automation="history-info-toggle"
        role="button"
        tabindex="0"
        :aria-label="timeInfo"
        @click="onShowTimeInfo"
        @keypress="onShowTimeInfo"
      />

      <div
        v-if="!!showTimeInfo"
        class="time-info"
        data-automation="time-info"
      >
        {{ timeInfo }}
      </div>
    </div>

    <button
      aria-label="Close History"
      class="barClose"
      data-automation="history-close"
      tabindex="0"
      @click="$emit('toggleHistory')"
    />
  </div>
</template>

<script>
import { localOffset } from '@/utils/timezone';
import dayjs from 'dayjs';
import capitalize from 'lodash/capitalize';
import debounce from 'lodash/debounce';
import delay from 'lodash/delay';
import sortBy from 'lodash/sortBy';

export const BUNDLE_HISTORY = 'BUNDLE_HISTORY';
export const RENDERING_HISTORY = 'RENDERING_HISTORY';

const dateFieldMap = {
  [BUNDLE_HISTORY]: 'createdTime',
  [RENDERING_HISTORY]: 'renderTime',
};

export default {
  name: 'History',
  props: {
    type: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    displayedId: {
      type: Number,
      required: true,
    },
  },
  emits: ['displayItem', 'toggleHistory'],
  data() {
    return {
      shouldEnableLeftNavigation: false,
      shouldEnableRightNavigation: false,
      showTimeInfo: false,
      timeInfo: `All times are displayed in local time ${localOffset()}`,
    };
  },
  computed: {
    historyList() {
      const formattedHistory = (this.items || []).map(item => {
        return {
          ...item,
          [this.dateField]: dayjs(item[this.dateField]).format('M/D/YY h:mma'),
        };
      });

      return sortBy(formattedHistory, 'id');
    },
    dateField() {
      return dateFieldMap[this.type];
    },
    typeProse() {
      if (this.type === RENDERING_HISTORY) {
        return 'rendering';
      } else if (this.type === BUNDLE_HISTORY) {
        return 'bundle';
      }
      return this.type;
    },
  },
  watch: {
    items: {
      handler: function() {
        this.$nextTick().then(() => {
          // Navigate to the selected item when the list is ready.
          const selectedElement = this.getSelectedElement();
          if (selectedElement) {
            selectedElement.scrollIntoView(false);
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.registerScrollEvent();
    this.registerResizeEvent();
    this.$nextTick().then(this.scrollAfterRender);
  },
  beforeUnmount() {
    this.$refs.innerContainer.removeEventListener(
      'scroll',
      this.debouncedCheckScroll
    );
    window.removeEventListener('resize', this.debouncedCheckScroll);
  },
  methods: {
    capitalize,
    debouncedCheckScroll: debounce(function() {
      const container = this.$refs.innerContainer;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;
      const SCROLL_OFFSET = 5;
      this.shouldEnableLeftNavigation = container.scrollLeft > SCROLL_OFFSET;
      this.shouldEnableRightNavigation =
        container.scrollLeft < maxScrollLeft - SCROLL_OFFSET;
    }, 250),
    registerScrollEvent() {
      this.$refs.innerContainer.addEventListener(
        'scroll',
        this.debouncedCheckScroll
      );
    },
    registerResizeEvent() {
      window.addEventListener('resize', this.debouncedCheckScroll);
    },
    onDisplayItem(item, index) {
      this.$emit('displayItem', item);
      if ('scrollIntoView' in this.$refs.item[index]) {
        this.$refs.item[index].scrollIntoView({ behavior: 'smooth' });
      }
    },
    onShowTimeInfo() {
      if (this.showTimeInfo) {
        clearTimeout(this.showTimeInfo);
        this.showTimeInfo = null;
        return;
      }

      this.showTimeInfo = delay(() => {
        this.showTimeInfo = false;
      }, 5_000);
    },
    /**
     * Get all the DOM Element objects for all the clickable history timestamps
     * in the header.
     * Note that this method returns a JavaScript array of Elements, not a
     * NodeList, so that modern array methods like .filter() will be available.
     * @returns {Array.<Element>}
     */
    getAllHistoryItems() {
      if (!this.$refs.item) {
        return [];
      }
      return Array.from(this.$refs.item);
    },
    /**
     * Get all the clickable history timestamp Elements that are currently
     * visible.
     * @returns {Array.<Element>}
     */
    getVisibleHistoryItems() {
      const containerRect = this.$refs.innerContainer.getBoundingClientRect();
      return this.getAllHistoryItems().filter(element => {
        const { left, right } = element.getBoundingClientRect();
        return left >= containerRect.left && right <= containerRect.right;
      });
    },
    /**
     * Find the Element that is a half-page to the left of the current view
     * and scroll it into view.
     */
    doScrollLeft() {
      const allHistoryItems = this.getAllHistoryItems();
      const visibleHistoryItems = this.getVisibleHistoryItems();

      if (allHistoryItems[0] === visibleHistoryItems[0]) {
        return;
      }

      const halfPageNumberOfItems = Math.ceil(visibleHistoryItems.length / 2);
      const firstVisibleItem = visibleHistoryItems[0];
      const firstVisibleItemIndex = allHistoryItems.findIndex(
        element => element === firstVisibleItem
      );
      const prevPageMiddleItemIndex = Math.max(
        0,
        firstVisibleItemIndex - halfPageNumberOfItems
      );
      const previousPageItem = allHistoryItems[prevPageMiddleItemIndex];
      previousPageItem.scrollIntoView({ behavior: 'smooth' });
    },

    /**
     * Find the Element that is a half-page to the right of the current view
     * and scroll it into view.
     */
    doScrollRight() {
      const allHistoryItems = this.getAllHistoryItems();
      const visibleHistoryItems = this.getVisibleHistoryItems();
      const lastItemIndex = allHistoryItems.length - 1;
      const lastHistoryItem = allHistoryItems[lastItemIndex];
      const lastVisibleHistoryItem =
        visibleHistoryItems[visibleHistoryItems.length - 1];

      if (lastHistoryItem === lastVisibleHistoryItem) {
        return;
      }

      const halfPageNumberOfItems = Math.ceil(visibleHistoryItems.length / 2);
      const lastVisibleItemIndex = allHistoryItems.findIndex(
        element => element === lastVisibleHistoryItem
      );
      const nextPageMiddleItemIndex = Math.min(
        lastItemIndex,
        lastVisibleItemIndex + halfPageNumberOfItems
      );
      const nextPageItem = allHistoryItems[nextPageMiddleItemIndex];
      nextPageItem.scrollIntoView({ behavior: 'smooth' });
    },

    getActiveElement() {
      if (!this.$refs.item) {
        return;
      }

      return this.$refs.item.find(el => el.className.includes('active'));
    },
    getSelectedElement() {
      if (!this.$refs.item) {
        return;
      }

      return this.$refs.item.find(el => el.className.includes('selected'));
    },

    /**
     * Find the Element that is active on load and scroll it into view to make
     * sure it is visible immediately.
     * If it cannot find the element, then nothing happens.
     * If it does find the element, then it scrolls to it.
     */
    scrollAfterRender() {
      const activeElement = this.getActiveElement();
      if (activeElement) {
        activeElement.scrollIntoView();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'Styles/shared/_colors';
@import 'Styles/shared/_mixins';

.historyBarContainer {
  align-items: center;
}

.listOuterContainer {
  border-right: none !important;
  overflow: auto !important;
  margin-right: 5px !important;

  .listbox {
    margin-bottom: 2px;
    background-color: $color-white;
    padding-top: 2px;
    border: none;
    text-align: right;

    .item {
      border-radius: 0;
      color: #666;
      height: auto;
      line-height: inherit;
      padding: 5px 10px;
      cursor: pointer;
      display: inline-block;
      width: auto;
      margin: 0 5px;
      @include control-visible-focus;

      &:hover {
        background-color: $color-light-grey;
        text-decoration: none;
      }

      &:focus, &:active {
        border-radius: 0;
      }

      &.selected {
        background-color: $color-primary;
        color: $color-primary-inverse;
      }

      &.active {
        font-weight: bold;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

div.info-toggle-container {
  display: flex;
  position: relative;
  align-items: center;
  border-right: 1px solid #c8c8c8;
  padding-right: 5px;
  margin-right: 10px;
  align-self: stretch;

  .time-info {
    @include normal-drop-shadow;
    display: flex;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: max-content;
    background-color: $color-info-background;
    border: 1px solid $color-info-border;
    color: $color-info;
    padding: 5px;
    z-index: 1;
    animation: delayed-fade-out 5s;
  }
}

@keyframes delayed-fade-out {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.barClose {
  cursor: pointer;
}
</style>
